<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('loyalty.store.overview')" class="catalog">
    <CCol cols="12" lg="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
              {{$t('sm.Spark_shop')}}
            </CCol>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <b-switch v-model="showInactive" class="m-0" size="is-small" @input="filterStoreData()">{{$t('common.show_inactive')}}</b-switch>
              </div>
            </CCol>           
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span class="text-uppercase">
                      <b>{{$t('common.Categories')}}</b> 
                    </span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('loyalty.store.addcategory')" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link" color="primary" @click="checkCategoriesWithinLicense()">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('common.Add_category')}}</span>
                      </CButton>                  
                    </div>
                  </CCol> 
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !categoriesLoading}">
                    <div v-if="categoriesLoading">
                      <loadingSpinner mode="auto" v-if="categoriesLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="categoriesTable"
                              class="data_table add_bottom_left_radius"
                              :data="categories"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="categoriesPaginated"
                              :per-page="categoriesPerPage"
                              :current-page.sync="categoriesPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize"
                              :row-class="(row, index) => ['pointer', row.loyalty_store_category_id_external === activeCategory.loyalty_store_category_id_external && 'active']"
                              @click="handleCategoryRowClick($event)">
                            
                            <template slot-scope="props">                      
                              <b-table-column field="image" label="" width="2.5%">
                                <div v-if="props.row.category_image_id && props.row.from_marketplace === 'N'"
                                    class="item_image"
                                    v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-category/' + props.row.loyalty_store_category_id_external + '/' + props.row.category_image_id + '.jpg' + '/' + clientToken + ')' }">
                                </div>
                                <div v-else-if="props.row.category_image_id && props.row.from_marketplace === 'Y'"
                                    class="item_image"
                                    v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-category-mp/' + props.row.loyalty_store_category_id_external + '/' + props.row.category_image_id + '.jpg' + '/' + clientToken + ')' }">
                                </div>                                
                                <div v-else class="item_icon d-inline-block align-middle pointer" @click="getCategoryProducts(props.row.loyalty_store_category_name, props.row.loyalty_store_category_id_external, props.row.from_marketplace);">
                                  <i class="fas fa-list"/>               
                                </div>         
                              </b-table-column>            
                              <b-table-column field="loyalty_store_category_name" :label="$t('common.Category')" :searchable="searchEnabled">
                                <span><b>{{props.row.loyalty_store_category_name}}</b></span>                               
                                <span class="d-block meta">
                                  <span v-if="showInactive">
                                    {{props.row.nr_of_products}} {{props.row.nr_of_products !== 1 ? $t('common.products') : $t('common.product')}}                                      
                                  </span>
                                  <span v-else>
                                    {{props.row.nr_of_active_products}} {{props.row.nr_of_active_products !== 1 ? $t('common.products') : $t('common.product')}}
                                  </span>                                    
                                </span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="props.row.active === 'N'" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>                                
                              </b-table-column>
                              <b-table-column field="description" :label="$t('common.Description')" :searchable="searchEnabled">
                                <span>{{props.row.description ? props.row.description : '-'}}</span>
                              </b-table-column>                              
                              <b-table-column field="target_groups" label="" class="text-right" width="10%">
                                <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                                    targetGroupsType="loyalty store category"
                                                    :targetGroupsIdExternal="props.row.loyalty_store_category_id_external"
                                                    popoverPlacement="left"/>
                              </b-table-column>
                              <b-table-column field="action" width="10%" :visible="checkPermission('loyalty.store.editcategory')">
                                <div class="d-flex justify-content-lg-end">
                                  <CButton color="primary"
                                           class="m-0"
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('common.Category_managed_by_owner')} : ''"
                                           @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('loyalty_store_category_details', { loyalty_store_category_id_external: props.row.loyalty_store_category_id_external }) : null">
                                    <i class="fas fa-pen"/>
                                  </CButton>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('common.no_categories_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>            
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol cols="6" lg="6" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">                    
                    <span v-if="activeCategory.loyalty_store_category_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeCategory.loyalty_store_category_name}}</b>
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('store.Products')}}</b></span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('loyalty.store.addproduct') && activeCategory.loyalty_store_category_id_external && activeCategory.from_marketplace === 'N'" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link" color="primary" @click="checkProductWithinLicense()">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('store.Add_product')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="activeCategory.loyalty_store_category_id_external">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !productsLoading}">
                    <div v-if="productsLoading">
                      <loadingSpinner mode="auto" v-if="productsLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="productsTable"
                               class="data_table add_bottom_right_radius"
                               :data="products"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="productsPaginated"
                               :per-page="productsPerPage"
                               :current-page.sync="productsPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :default-sort-direction="defaultSortDirection"
                               :sort-icon="sortIcon"
                               :sort-icon-size="sortIconSize"
                               :row-class="(row, index) => checkPermission('loyalty.store.editproduct') && row.from_marketplace === 'N' && 'pointer'"
                               @click="checkPermission('loyalty.store.editproduct') ? handleProductRowClick($event) : null">
                            
                            <template slot-scope="props">                              
                              <b-table-column field="image" label="" width="2.5%">
                                <div v-if="props.row.product_image_id && props.row.from_marketplace === 'N'"
                                     class="item_image d-inline-block align-middle"
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                                </div>
                                <div v-else-if="props.row.product_image_id && props.row.from_marketplace === 'Y'"
                                     class="item_image d-inline-block align-middle"
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product-mp/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                                </div>
                                <div v-else-if="props.row.loyalty_store_partner_product_external_id"
                                     class="item_image d-inline-block align-middle"
                                     v-bind:style="{ backgroundImage: 'url(' + props.row.loyalty_store_partner_product_image_url + ')' }">
                                </div>                                
                                <div v-else class="item_icon d-inline-block align-middle">
                                  <i class="fas fa-box"/>               
                                </div>                     
                              </b-table-column>
                              <b-table-column field="product_name" :label="$t('common.Product')" :searchable="searchEnabled" width="35%">
                                <span><b>{{props.row.product_name}}</b></span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="props.row.active === 'N'" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>                                                                
                              </b-table-column>
                              <b-table-column field="product_limitations" :label="$t('store.Product_limitations')">
                                <div v-if="props.row.product_limitations.length > 0" class="item_tags d-flex flex-row">
                                  <!-- <span>{{props.row.product_limitations}}</span> -->
                                  <b-tag v-for="limitation in props.row.product_limitations" :key="limitation.loyalty_store_product_limitation_id_external" class="mt-1 info">
                                    <span><b>{{limitation.limitation_value}}</b> {{parseInt(limitation.limitation_value) !== 1 ? $t('store.orders') : $t('store.order')}} <b>{{limitation.loyalty_store_product_limitation_type_name.toLowerCase()}}</b></span>
                                  </b-tag>
                                </div>                                                                
                                <div v-else>
                                  <span>-</span>
                                </div>
                              </b-table-column>                              
                              <b-table-column field="nr_of_product_variations" :label="$t('store.Nr_of_variations')" sortable>
                                <span v-if="props.row.nr_of_product_variations <= 1">{{props.row.nr_of_product_variations}} {{props.row.nr_of_product_variations === 1 ? $t('store.variation') : $t('store.variations')}}</span>
                                <productVariationsPopover v-else
                                                          :productIdExternal="props.row.loyalty_store_product_id_external"
                                                          :productVariationsAmount="props.row.nr_of_product_variations"
                                                          :productCurrency="props.row.loyalty_store_partner_product_currency ? props.row.loyalty_store_partner_product_currency : null"
                                                          popoverPlacement="left"/>
                              </b-table-column>
                              <b-table-column field="action" :visible="checkPermission('loyalty.store.editproduct')">
                                <div class="d-flex justify-content-lg-end">
                                  <CButton color="primary"
                                           class="m-0"
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('store.Product_managed_by_owner')} : ''"
                                           @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('loyalty_store_product_details', { loyalty_store_category: props.row.category, loyalty_store_product_id_external: props.row.loyalty_store_product_id_external }) : null">
                                    <i class="fas fa-pen"/>
                                  </CButton>                                  
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('common.no_products_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>          
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('loyalty.Select_category_for_products')}}</span>
                  </CCol>
                </CRow>          
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>       
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import noPermission from '@/components/common/noPermission.vue';
import targetGroupPopover from '@/components/common/targetGroupPopover.vue'
import productVariationsPopover from '@/components/loyalty/productVariationsPopover.vue';

export default {
  name: 'Catalog',
  components: {
    loadingSpinner,
    noPermission,
    targetGroupPopover,
    productVariationsPopover
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,
      productLicenses: {
        loyalty_licence_tag: null
      },          
      activeCategory: {
        loyalty_store_category_name: null,
        loyalty_store_category_id_external: null,
        from_marketplace: 'N'
      },
      categories: [],
      categoriesLoading: false,
      categoriesLoaded: false,
      categoriesPaginated: false,
      categoriesPerPage: 8,
      categoriesPage: 1,
      products: [],
      productsTotal: 0,
      productsLoading: false,
      productsLoaded: false,
      productsPaginated: false,
      productsPerPage: 8,
      productsPage: 1,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: true,
      showInactive: false    
    }
  },
  methods: {
    checkCategoriesWithinLicense() {
      let licenseTag = this.productLicenses.loyalty_license_tag;
      let licenseName = this.productLicenses.loyalty_license_name;

      if((['explorer'].includes(licenseTag) && this.categories.length < 3) || (['business'].includes(licenseTag) && this.categories.length < 10) || ['enterprise'].includes(licenseTag)) {
        this.openSidebarRight('loyalty_store_category_details', { loyalty_store_category_id_external: null })
      } else {
        this.openFeatureUnavailableModal({ license_name: licenseName });
      }
    },
    checkProductWithinLicense() {
      let licenseTag = this.productLicenses.loyalty_license_tag;
      let licenseName = this.productLicenses.loyalty_license_name;

      if((['explorer'].includes(licenseTag) && this.productsTotal < 5) || (['business'].includes(licenseTag) && this.productsTotal < 15) || ['enterprise'].includes(licenseTag)) {        
        this.openSidebarRight('loyalty_store_product_details', { loyalty_store_category: this.activeCategory, loyalty_store_product_id_external: null })
      } else {
        this.openFeatureUnavailableModal({ license_name: licenseName });
      }
    },    
    handleCategoryRowClick(event) {
      this.getCategoryProducts(event.loyalty_store_category_name, event.loyalty_store_category_id_external, event.from_marketplace);
    },
    handleProductRowClick(event) {
      event.from_marketplace === 'N' ? this.openSidebarRight('loyalty_store_product_details', { loyalty_store_category: event.category, loyalty_store_product_id_external: event.loyalty_store_product_id_external }) : null
    },    
    filterStoreData() {
      this.getCategories();

      if(this.activeCategory.loyalty_store_category_name && this.activeCategory.loyalty_store_category_id_external) {
        this.getCategoryProducts(this.activeCategory.loyalty_store_category_name, this.activeCategory.loyalty_store_category_id_external, this.activeCategory.from_marketplace);
      }     
    },
    getCategoryDetails(categoryIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/category/' + categoryIdExternal)
      .then(res => {
        let updatedCategory = res.data.data;        
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.categoriesTable !== undefined ) {
          let categoryIndex = this.$refs.categoriesTable.data.findIndex(x => x.loyalty_store_category_id_external == updatedCategory.loyalty_store_category_id_external);

          this.$refs.categoriesTable.data[categoryIndex].nr_of_products = updatedCategory.nr_of_products;
          this.$refs.categoriesTable.data[categoryIndex].nr_of_active_products = updatedCategory.nr_of_active_products;
        }          
      })
      .catch(err => {
        console.error(err); 
      });    
    },         
    getCategories() {
      // Start the loader
      if(!this.categoriesLoaded) this.categoriesLoading = true;
      // Get the categories
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/categories/overview')
      .then(res => {
        this.categories = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.categories = this.categories.filter( i => ['Y'].includes( i.active ) );        
        // Check if the pagination should be enabled
        (this.categories.length > this.categoriesPerPage) ? this.categoriesPaginated = true : this.categoriesPaginated = false;
        // Stop the loader
        this.categoriesLoading = false;
        // Update the categoriesLoaded value
        this.categoriesLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getCategoryProducts(categoryName, categoryIdExternal, categoryFromMarketplace) {
      // Check if a different category is selected
      if(this.activeCategory.loyalty_store_category_id_external !== categoryIdExternal) {
        // Start the loader
        this.productsLoading = true;      
        // Clear the products
        this.products = [];
        // Update the active data
        this.activeCategory = { 
          loyalty_store_category_name: categoryName,
          loyalty_store_category_id_external: categoryIdExternal,
          from_marketplace: categoryFromMarketplace          
        };
      }
      // Get the products
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/category/' + categoryIdExternal + '/products')
      .then(res => {
        this.products = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.products = this.products.filter( i => ['Y'].includes( i.active ) );
        // Enable the pagination if necessary
        (this.products.length > this.productsPerPage) ? this.productsPaginated = true : this.productsPaginated = false;
        // Update the category details
        this.getCategoryDetails(categoryIdExternal);       
        // Stop the loader
        this.productsLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getProductsTotal() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/products/total')
      .then(res => {
        this.productsTotal = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  async mounted(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.productLicenses = await this.fetchProduuctLicenseData();

    this.getPlatformPermissions();
    this.getProductsTotal();
    this.getCategories();

    this.$bus.$on('update_loyalty_categories', () => {
      this.getCategories();
    });
    
    this.$bus.$on('update_loyalty_category_products', (category) => {
      this.getCategoryProducts(category.loyalty_store_category_name, category.loyalty_store_category_id_external, category.from_marketplace);
      this.getCategories();
    });
  },
  beforeDestroy() {
    this.$bus.$off('update_loyalty_categories');
    this.$bus.$off('update_loyalty_category_products');
  }  
}
</script>